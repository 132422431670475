



























import Record from '@/components/oai/Record.vue';
import RemoteServices from '@/services/RemoteServices';
import { Component, Vue } from 'vue-property-decorator';

@Component({ components: { Record } })
export default class OaiListRecords extends Vue {
    xml = '';
    identifier = 'oai:cenoteando.org:Cenotes/1';

    async created(): Promise<void> {
        await this.getRecordXml();
    }

    async getRecordXml(): Promise<void> {
        await this.$store.dispatch('loading');
        try {
            this.xml = await RemoteServices.getRecordXml(this.identifier);
        } catch (error) {
            await this.$store.dispatch('error', error);
        }
        await this.$store.dispatch('clearLoading');
    }
}
